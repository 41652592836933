.container {
    font-family: "Lato", sans-serif;
  }

  .button {
    @apply bg-[#111828] !py-2 !px-4 text-white !rounded-md !cursor-pointer !font-normal !text-base !shadow-sm !transition-all !ease-in-out;
    height: auto !important;
    border: none !important
  }

  .buttonBlue {
    @apply bg-[#007bff] !py-2 !px-4 text-white !rounded-md !cursor-pointer !font-normal !text-base !shadow-sm !transition-all !ease-in-out;
    height: auto !important;
    border: none !important
  }

  
  .buttonBlack {
    @apply  inline-flex items-center justify-center gap-2 px-4 py-4 bg-black text-white text-sm font-medium rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200 ease-in-out;
    /* height: auto !important;
    border: none !important; */
  }
  
  
  .button:hover {
    color: white !important;
    background-color: #3d3d3d !important;
    border: none !important;
  }

  .buttonBlue:hover {
    @apply !bg-blue-600 !text-white !border-none
  }