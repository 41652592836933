.procedures-container {
    background-color: #ffffff;
  
    gap: 10px;
    padding: 5px 2px;
  }
  
  .procedure-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 4px 24px 0px #00000012;
    background-color: #ffffff;
    height: 45px;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 5px;
  }
  
  .procedure-name {
    flex-basis: 100%;
  }
  
  .procedure-details {
    display: flex;
    flex-basis: 100%;
    gap: 10px;
    justify-content: flex-end;
  }
  
  .procedure-checked {
    width: 42px;
  }
  
  .procedure-dosage {
    width: fit-content !important;
  }
  
  .dosageSelect {
    height: 30px !important;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding-left: 10px;
    width: 150px !important;
    background-color: transparent;
  }
  
  .procInput {
    /* Styles for the text input */
    height: 30px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding-left: 10px;
    width: 83px;
    background-color: transparent;
  }
  
  .proctitleStyling {
    font-family: "Raleway", sans-serif !important;
    font-size: 27px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
    text-align: left;
    color: #000000;
    margin-top: 20px;
  }
  
  .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    padding: 18px !important;
    border-radius: 6px !important;
  }
  
  /* .css-rnmm7m-MuiPaper-root-MuiDialog-paper::-webkit-scrollbar {
    display: none !important; /* For Chrome, Safari, and Opera */
  /* } */
   .note-input-row {
    border-radius: 6px;
    margin-bottom: 10px;
    grid-column: span 2; /* Span the input row across both columns */
  }
  
  .note-input {
    height: 30px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding-left: 10px;
    background-color: transparent;
  }
  
  .noteSpan {
    width: 12%;
    height: 45px;
    font-family: "Raleway", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.40799999237060547px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  
  .procsearchInput {
    background-color: #f1f1f1;
    border-radius: 6px !important;
    width: 100% !important;
    height: 52px !important;
    padding-left: 10px;
  }
  
  .procactiveStyling {
    margin-top: 20px;
    font-family: "Raleway", sans-serif !important;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.40799999237060547px;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .outer-procedures-container {
    position: relative;
    overflow: hidden;
    height: 50vh;
    margin-bottom: 20px;
  }
  
  .inner-procedures-container {
    overflow-y: scroll;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  /* .procedures-container::-webkit-scrollbar {
    display: none;
  } */
  
  .procedures-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .button {
    @apply bg-[#111828] !py-2 !px-4 text-white !rounded-md !cursor-pointer !font-normal !text-base !shadow-sm !transition-all !ease-in-out;
    height: auto !important;
    border: none !important
  }